import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyStateComponent, EmptyStateConfig, authenticationGuard } from '@hxp/kernel';
import { AppDetailsView } from './views/app-details/app-details.view';
import { AppUserRightsView } from './views/app-user-rights/app-user-rights.view';
import { ApplicationOverviewView } from './views/application-overview/application-overview.view';
import { AppsView } from './views/apps/apps.view';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticationGuard],
    component: AppsView,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: EmptyStateComponent,
        data: {
          emptyStateDefaults: {
            icon: 'apps',
            text: 'nucleus.applications.components.empty-state.action',
          },
        } as EmptyStateConfig,
      },
      {
        path: 'not-found',
        loadChildren: () => import('@hxp/nucleus').then((m) => m.PageDoesNotExistModule),
      },
      {
        path: ':appId',
        canActivate: [authenticationGuard],
        component: AppDetailsView,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'overview',
          },
          {
            path: 'overview',
            component: ApplicationOverviewView,
          },
          {
            path: 'user-rights',
            component: AppUserRightsView,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppsRoutingModule {}
