import { Route } from '@angular/router';
import { EmptyStateComponent, EmptyStateConfig, authenticationGuard } from '@hxp/kernel';

export const subscriptionsRoutes: Route[] = [
  {
    path: '',
    canActivate: [authenticationGuard],
    loadComponent: () => import('./views/subscriptions/subscriptions.view').then((m) => m.SubscriptionsView),
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: EmptyStateComponent,
        data: {
          emptyStateDefaults: {
            icon: 'globe',
            text: 'nucleus.subscriptions.components.empty-state.action',
          },
        } as EmptyStateConfig,
      },
      {
        path: 'not-found',
        loadChildren: () => import('@hxp/nucleus').then((m) => m.PageDoesNotExistModule),
      },
      {
        path: ':id',
        canActivate: [authenticationGuard],
        loadComponent: () => import('./views/subscription-details/subscription-details.view').then((m) => m.SubscriptionDetailsView),
      },
    ],
  },
];
