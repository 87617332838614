import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from '@hxp/kernel';
import { DashboardView } from './dashboard/dashboard.view';
import { ContentCannotBeViewedView } from './shared/components/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { EnvironmentValidationGuard } from './shared/guards/environment-validation.guard';
import { EnvironmentIdResolverService } from './shared/resolvers/environment-id-resolver.service';
import { UnassignedView } from './unassigned/unassigned.view';

export const ROUTES: Routes = [
  {
    path: '',
    resolve: {
      environmentId: EnvironmentIdResolverService,
    },
    canActivate: [authenticationGuard],
    pathMatch: 'full',
    children: [],
  },
  {
    path: 'environments/:environmentId',
    component: DashboardView,
    canActivate: [authenticationGuard, EnvironmentValidationGuard],
  },
  // redirect old links using 'tenant' to new path 'environment'
  {
    path: 'tenants/:environmentId',
    redirectTo: 'environments/:environmentId',
  },
  {
    path: 'unassigned',
    component: UnassignedView,
    canActivate: [authenticationGuard],
  },
  {
    path: '**',
    component: ContentCannotBeViewedView,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [EnvironmentIdResolverService, EnvironmentValidationGuard],
})
export class RoutingModule {}
