import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from '@hxp/kernel';
import { AccountAppListView } from './account-app-list/account-app-list.view';
import { UserRightsView } from './user-rights/user-rights.view';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticationGuard],
    component: AccountAppListView,
    children: [
      {
        path: 'not-found',
        loadChildren: () => import('@hxp/nucleus').then((m) => m.PageDoesNotExistModule),
      },
      {
        path: ':id',
        component: UserRightsView,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountAccessRoutingModule {}
